import React, {useState, useContext, useEffect} from 'react';
import { Container, Alert, Spinner, Button, Modal, Accordion } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const Home=()=>{
	const { user, postCall, getQpLoad, Qp, setQpp } = useContext(AppContext);
	const [ qp, setQp ] = useState();
	const [ examDuration, setExamDuration ] = useState();
	let index =0;
	let qp_len=0;
	var x;
	useEffect(()=>{
		if (qp!==undefined) {
			qp_len = Object.keys(qp).length;
			let duration = 0;
			if (examDuration!==undefined) {
				duration = examDuration;
			}      	
			x = setInterval(()=>{
				duration--;
				document.querySelector('#time_remain').innerHTML = 'Time remaining = '+Math.floor(duration/60)+' : '+duration%60;
				if (duration<1) {
					clickFinish();
				}
			},1000);
		}
	},[qp]);
	const [ qpId, setQpId ] = useState();
	const [ getTpLoad, setGetTpLoad ] = useState(false);
	const [ tpModal, setTpModal ] = useState(false);
	const applyNow=(qpid,dur)=>{
		if (qpid!=="") {
			$.confirm({
		    title: 'Confirm Starting test!',
		    content: 'Once its started Timer will start. and automatically submit the answers when time is over.',
		    type:'blue',
		    buttons: {
		        YES: function () {
		        	setQpId(qpid);
		        	setGetTpLoad(true);
		        	setExamDuration(parseInt(dur)*60);		        	
		            postCall('get/test/paper/',{tp_id:qpid.substring(1)}).then(res=>{
		            	setQp(res.data);
		            	setTpModal(true);
		            	setGetTpLoad(false);
		            	postCall('start/test/paper/',{tp_id:qpid.substring(1)}).then(res=>{}).catch(err=>{});	
		            }).catch(err=>{
		            	setGetTpLoad(false);
		            	setQpId();
		            });
		        },
		        NO: function () {
		            
		        },
		    }
		});
		}
	}
	const clickNext=()=>{
		if (index<qp_len-1) {
			document.querySelector('#qs_'+index).classList.remove('active');		
			index++;
			document.querySelector('#qs_'+index).classList.add('active');
			if(index>0){
				document.querySelector('#qs_prev').classList.remove('hide');
				if (index===qp_len-1) {
					document.querySelector('#qs_next').classList.add('hide');
					document.querySelector('#qs_finish').classList.remove('hide');
				}
			}else if(index===0){
				document.querySelector('#qs_prev').classList.remove('hide');
			}
		}
	}
	const clickPrev=()=>{
		if (index>0) {
			document.querySelector('#qs_'+index).classList.remove('active');		
			index--;
			document.querySelector('#qs_'+index).classList.add('active');
			document.querySelector('#qs_finish').classList.add('hide');
			document.querySelector('#qs_next').classList.remove('hide');
			if (index===0) {
				document.querySelector('#qs_prev').classList.add('hide');
			}
		}
	}
	const castAnswer=(qid,ans)=>{
		if (qid!==""&&ans!==""&&qpId!==undefined&&qpId!=="") {
			postCall('enter/test/paper/answers/',{q_id:qid.substring(1),qp_id:qpId.substring(1),answer:ans}).then(res=>{
				console.log(res.data);
			}).catch(err=>{
				console.log('Something went wrong submitting the answer!')
			});
		}
	}
	const clickFinish=()=>{
		clearInterval(x);
		setTimeout(()=>{
			postCall('complete/test/paper/',{qp_id:qpId.substring(1)}).then(res=>{
				setQpp(res.data);
				setTpModal(false);
				setExamDuration();
				setQp();
			}).catch(err=>{

			});
			
		},1000);
	}
	return(
			<Container className="py-3"  >
				{
					user!==undefined&&user.account!==undefined?(
						user.account.approve!==undefined&&user.account.approve?(
							getQpLoad?(
								<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
							):(
								Qp!==undefined?(
									Object.keys(Qp).length>0?(
										Object.keys(Qp).map((qs,q_i)=>(
											<div className="q-paper d-grid bdr-1 br3 mb-3" key={`qp_${q_i}`}>
												<div className="tc2 py-2 px-3 bdr-1b d-flex al-center" >
													<span className="f20" >{Qp[qs].name}</span>
													{
														Qp[qs].completed?(
															<span className="sucess-tag f16 px-3 py-1 mx-2 br3" ><i className="bi bi-check-circle"></i> Exam completed.</span>
														):''
													}
												</div>
												{
													Qp[qs].question_sets!==undefined&&Object.keys(Qp[qs].question_sets).length>0?(
														Object.keys(Qp[qs].question_sets).map((qt,qt_i)=>(
															<div className="q-type d-grid bdr-1b mb-3 p-3" key={`qs_${qt_i}`} >
																<h5 className="mb-2 tc2" >{Qp[qs].question_sets[qt].name} / {Qp[qs].question_sets[qt].time_period} Minutes</h5>
																<div dangerouslySetInnerHTML={{ __html: Qp[qs].question_sets[qt].instructions }} />
																{
																	Qp[qs].question_sets[qt].completed?(
																		<Alert variant="success sucess-text" ><i className="bi bi-check-circle"></i> Completed</Alert>
																	):(
																		qpId!==undefined&&qpId===qt&&getTpLoad?(
																			<Button className="br3" variant="outline-primary" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
																		):(
																			Qp[qs].question_sets[qt].next?(
																				<Button onClick={()=>{applyNow(qt,Qp[qs].question_sets[qt].time_period)}} className="br3" variant="primary" >START TEST</Button>
																			):(
																				<Button className="br3" variant="primary" disabled>START TEST</Button>
																			)
																			
																		)
																	)
																}
															</div>
														))
													):'Question set not found!'
												}
											</div>
										))
									):(<Alert variant="info" >Question paper not found!</Alert>)
								):(<Alert variant="danger" >Something went wrong loading question paper!</Alert>)
							)
						):(
							<Alert variant="info" >Your account need to be verified!. Please try login after some time. </Alert>
						)
					):(
						<Alert variant="warning" className="t-red br-3" >Something went wrong!</Alert>
					)
				}
				<Modal size="xl" show={tpModal} backdrop="static">
		        	<Modal.Header><span id="time_remain" className="f22 tc2 f100" ></span></Modal.Header>
		        	<Modal.Body id="qs" >
		        		{
		        			qp!==undefined?(
		        				Object.keys(qp).length>0?(
		        					Object.keys(qp).map((ques,ques_i)=>(
		        						<div id={`qs_${ques_i}`} className={ques_i===0?'question active':'question'} key={`qn_${ques_i}`} >
		        							<h3 className="tc2" >{qp[ques].name}</h3>
		        							{
		        								qp[ques].show?(
		        									<Accordion className="mb-3" defaultActiveKey="0" >
												      <Accordion.Item  eventKey="0">
												        <Accordion.Header>Instructions</Accordion.Header>
												        <Accordion.Body>
												        	<div className="tc3" dangerouslySetInnerHTML={{ __html: qp[ques].instruction }} />
												        </Accordion.Body>
												      </Accordion.Item>
												    </Accordion>
		        								):(
		        									<Accordion className="mb-3" >
												      <Accordion.Item  eventKey="0">
												        <Accordion.Header>Instructions</Accordion.Header>
												        <Accordion.Body>
												        	<div className="tc3" dangerouslySetInnerHTML={{ __html: qp[ques].instruction }} />
												        </Accordion.Body>
												      </Accordion.Item>
												    </Accordion>
		        								)
		        							}       							
		        							
		        							<div className="d-grid bdr-1 br3">
			        							<h5 className="tc2 bg-greay-2 mb-0 py-2 px-3" >{ques_i+1} ) Question</h5>
			        							<div className="tc3 bg-greay-1 p-3 bdr-1b" dangerouslySetInnerHTML={{ __html: qp[ques].question }} />
			        							<div className="options d-flex just-center bg-greay-1 p-3">
			        								<div class="form-check form-check-inline">
													  <input onChange={()=>{castAnswer(ques,'a')}} class="form-check-input" type="radio" name={`op${ques}`}  value="a" />
													  <label class="form-check-label" >A</label>
													</div>
													<div class="form-check form-check-inline">
													  <input onChange={()=>{castAnswer(ques,'b')}} class="form-check-input" type="radio" name={`op${ques}`}  value="b" />
													  <label class="form-check-label" >B</label>
													</div>
													<div class="form-check form-check-inline">
													  <input onChange={()=>{castAnswer(ques,'c')}} class="form-check-input" type="radio" name={`op${ques}`}  value="c" />
													  <label class="form-check-label" >C</label>
													</div>
													<div class="form-check form-check-inline">
													  <input onChange={()=>{castAnswer(ques,'d')}} class="form-check-input" type="radio" name={`op${ques}`}   value="d" />
													  <label class="form-check-label" >D</label>
													</div>
			        							</div>
		        							</div>
		        						</div>
		        					))
		        				):'Questions not found!'
		        			):'Something went wrong!'
		        		}
		        	</Modal.Body>
		        	<Modal.Footer>
		        	<Button onClick={clickPrev} varient="primary" className="br2 hide" id="qs_prev"  >Previous</Button>
		        	<Button onClick={clickNext} varient="primary" className="br2" id="qs_next" >Next</Button>
		        	<Button onClick={clickFinish} varient="primary" className="br2 hide" id="qs_finish" >Finish</Button>
		        	</Modal.Footer>
        		</Modal>
			</Container>
		)
}