import React, {useState, createContext, useEffect} from 'react';
import axios from "axios";
import { LocalServer } from './templates/WebIp';
import { Menu } from './templates/menu';
import { Dashboard } from './templates/dashboard';
import { Authentication } from './templates/authentication';
import $ from 'jquery';
import 'jquery-confirm';
export const AppContext = createContext();

function App() {
  var link = LocalServer();
  const [ token, setToken ] = useState();
  const [ user, setUser ] = useState();  
  const [ page, setPage ] = useState();
  const [ getQpLoad, setGetQpLoad ] = useState(false);
  const [ Qp, setQpp ] = useState();
  const [ allStudents, setAllStudents ] = useState();
  const [ getAllStudentsLoad, setGetAllStudentsLoad ] = useState(false);
  const [ markList, setMarkList ] = useState();
  const [ getMarkListLoad, setGetMarkListLoad ] = useState(false);
  const getCall=(loadfn,path,setfn)=>{
    loadfn(true);
    axios.get(`${link}api/${path}`,{
          headers:{
            Authorization: 'Bearer '+token
          }
        }).then((response)=>{
          loadfn(false);
          setfn(response.data);
        }).catch((err)=>{
          loadfn(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setToken();
            }else if(err.response.status === 404){
                console.log(path, err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log(path, err.response.status,':',err.response.statusText);
            }else{
                console.log(path, err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
        }
    });
  }
  const postCall=(path,data)=>{
    return new Promise((resolve,reject)=>{
      axios.post(`${link}api/${path}`,data,{
            headers:{
              Authorization:token!==undefined?'Bearer '+token:''
            }
          }).then((response)=>{
            resolve({data:response.data});
          }).catch((err)=>{
            if (err!==undefined&&err.response!==undefined) {
              if(err.response.status === 401){
                  setToken();
              }else if(err.response.status === 404){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else if(err.response.status === 500){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else{
                  console.log(path, err.response.status,':',err.response.data);
              }
            }else{
              $.dialog({
                title: "Can't reach server!",
                content: 'Please check internet connection!',
              });
            }
            reject();
      });
    });
  }
  const [ newStudents, setNewStudents ] = useState();
  const [ getNewStudentsLoad, setGetNewStudentsLoad ] = useState(false);  
  useEffect(()=>{
    if (user!==undefined&&user.type!==undefined) {
      if (user.type==='student'&&user.account!==undefined&&user.account.approve) {
        setPage('home');
        getCall(setGetQpLoad,'get/question/paper/',setQpp);
      }else if (user.type==='admin') {
        setPage('admin_home');
        getCall(setGetNewStudentsLoad,'admin/new/students/',setNewStudents);
      }
    }
  },[user]);
  const [ getManualMarkListLoad, setGetManualMarkListLoad ] = useState(false);
  const [ manualMarkList, setManualMarkList ] = useState();
  return (
    <AppContext.Provider value={{ link, getCall, postCall, user, setUser, setToken, page, setPage, getQpLoad, Qp, setQpp, newStudents,
    setNewStudents, getNewStudentsLoad, setGetNewStudentsLoad, allStudents, setAllStudents, getAllStudentsLoad, setGetAllStudentsLoad,
    markList, setMarkList, getMarkListLoad, setGetMarkListLoad, getManualMarkListLoad, setGetManualMarkListLoad, manualMarkList, setManualMarkList  }} >
      {
        token !== undefined?(
          <div className="wrapper">
            <Menu />
            <Dashboard />
          </div>        
        ):(
          <Authentication />
        )
      }      
    </AppContext.Provider>
  );
}

export default App;
