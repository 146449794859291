import React, {useState, useContext, useEffect, useRef } from 'react';
import { Container, Alert, Spinner, Button, Modal, Table, Row, Col, Pagination } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import ReactToPrint from 'react-to-print';
import { AppContext } from '../App';


export const MarkList=()=>{
	const { postCall, markList, getMarkListLoad } = useContext(AppContext);


	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState();
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=25;	
	// number of pages in pagination
	const pageCount = 10;
	useEffect(()=>{
		if (markList!==undefined) {
			let numStud = Object.keys(markList).length;
			setNumPage(Math.ceil(numStud/itemCount));
			setActivePage(0);
		}
	},[markList]);
	useEffect(()=>{
		setStart(0);
		setIndexStart(0);
	},[numPage]);


	const [ activeStudent, setActiveStudent ] = useState();
	const [ getSheetLoad, setGetSheetLoad ] = useState(false);
	const [ markSheet, setMarkSheet ] = useState();
	var chart;
	const [ markSheetModal, setMarkSheetModal ] = useState(false);
	useEffect(()=>{
		if (markSheetModal) {
			let chartStatus = Chart.getChart("myChart");
			if (chartStatus !== undefined) {
			  chartStatus.destroy();
			}
			var canvas = document.getElementById('myChart').getContext("2d");		
			(async function() {
			 chart = new Chart(canvas,
			    {
			      type: 'bar',
			      options: {
			        scales: {
			            y: {
			                suggestedMin: 0,
			                suggestedMax: 10
			            }
			        }
				  },
			      data: {
			        labels: ['LA','AR','VR','MR','NA','SA','PA'],
			        datasets: [
			          {
			            label: 'Sten Score',
			            backgroundColor:'#4c626c',
			            data: [markSheet.graph.LA!==undefined?markSheet.graph.LA:0,markSheet.graph.AR!==undefined?markSheet.graph.AR:0,
			            	markSheet.graph.VR!==undefined?markSheet.graph.VR:0,markSheet.graph.MR!==undefined?markSheet.graph.MR:0,
			            	markSheet.graph.NA!==undefined?markSheet.graph.NA:0,markSheet.graph.SA!==undefined?markSheet.graph.SA:0,
			            	markSheet.graph.PA!==undefined?markSheet.graph.PA:0]
			          }
			        ]
			      }
			    }
			  );

			})();
		}
	},[markSheetModal]);
	useEffect(()=>{
		if (markSheet!==undefined) {
			setMarkSheetModal(true);			
		}
	},[markSheet]);
	const getMarkSheet=(student)=>{
		if (student!=="") {
			setActiveStudent(student);
			setGetSheetLoad(true);
			postCall('admin/get/mark/sheet/',{s_id:student.substring(1)}).then(res=>{
				setMarkSheet(res.data);
				setActiveStudent();
				setGetSheetLoad(false);
			}).catch(err=>{
				setActiveStudent();
				setGetSheetLoad(false)
			});
		}
	}
	const printable=useRef();
	return(
			<Container className="py-3"  >
				{
					getMarkListLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					):(
						markList!==undefined?(
						Object.keys(markList).length>0?(
							<span className="d-grid tc1 f20 f100 px-2 py-1 bg-greay-1 table-head-border" >Mark List</span>
							):''
						):''
					)
				}
				{
					markList!==undefined?(
						Object.keys(markList).length>0?(
							<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th><span className="tc2" >Name</span></th>
							          <th><span className="tc2" >Class</span></th>
							          <th><span className="tc2" >School</span></th>
							          <th><span className="tc2" >Contact Number</span></th>
							          <th><span className="tc2" >Email</span></th>
							          <th><span className="tc2" ></span></th>
							        </tr>
							    </thead>
							    <tbody>
							    {
									Object.keys(markList).map((student,s_i)=>(
										activePage!==undefined&&s_i>=activePage*itemCount&&s_i<activePage*itemCount+itemCount?(
											<tr key={`satud_${s_i}`}>
												<td><span className="tc2" >{markList[student].name}</span></td>
												<td><span className="tc2" >{markList[student].class}</span></td>
												<td><span className="tc2" >{markList[student].institute}</span></td>
												<td><span className="tc2" >{markList[student].phone_numbers}</span></td>
												<td><span className="tc2" >{markList[student].email}</span></td>
												<td>
												{
													activeStudent===student&&getSheetLoad?(
														<Button size="sm" className="br3 btn-enable"><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
													):(
														<Button onClick={()=>{getMarkSheet(student)}} size="sm" className="br3" variant="primary" ><i className="bi bi-check-circle"></i> Get Mark Sheet</Button>
													)
												}
												</td>
											</tr>
										):''
									))
								}
								</tbody>
							</Table>
						):(<Alert variant="info mt-3" >Mark List NOT found! </Alert>)
					):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
				}
				<div className="d-grid just-center py-2">
					{
					    numPage!==undefined&&numPage>1?(
							<Pagination>
						      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
						      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
						      	{
						      		Array(numPage).fill('1').map((item,i)=>(
						      			i>=indexStart&&i<indexStart+pageCount?(
				      						activePage!==undefined&&activePage===i?(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
				      						):(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
				      						)
				      					):''
				      				))
						      	}				      	
						      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
						      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
						    </Pagination>
						):''
					}
			    </div>
				<Modal size="xl" show={markSheetModal} backdrop="static" onHide={()=>{setMarkSheetModal(false)}}>
		        	<Modal.Header closeButton>Aptitude Test Report Sheet</Modal.Header>
		        	<Modal.Body ref={printable} >
		        		<div className="p-5 row">
				        	{
								markSheet!==undefined?(
								<>	<div className="d-grid mb-3 bdr-1 bg-greay-1 py-2 br3">	
										<span className="f22 tex-center f200 tc1">{markSheet.exam_name}</span>
									</div>						
					        		<Row className="mb-3" >
					        			<Col xs={12} sm={6} md={6} >
					        				<div className="d-flex" ><span className="attr-key" >Name</span>:<span className="px-2" >{markSheet.name}</span></div>
					        				<div className="d-flex" ><span className="attr-key" >Phone</span>:<span className="px-2" >{markSheet.phone_numbers}</span></div>
					        			</Col>
					        			<Col xs={12} sm={6} md={6} >
					        				<div className="d-flex" ><span className="attr-key" >School</span>:<span className="px-2" >{markSheet.institute}</span></div>
					        				<div className="d-flex" ><span className="attr-key" >Class</span>:<span className="px-2" >{markSheet.class}</span></div>
					        			</Col>
					        		</Row>
					        	</>
					        	):''
				        	}
			        		<div className="d-grid">
			        		{
								markSheet!==undefined?(
									Object.keys(markSheet.marks).length>0?(
										<Table bordered className="tbl-bg-blue mb-3" >
										    <thead>
										        <tr>
										          <th><span className="tc2" >SI No</span></th>
										          <th><span className="tc2" >Sub.Test</span></th>
										          <th><span className="tc2" >Score Obtained</span></th>
										          <th><span className="tc2" >Sten Score</span></th>
										          <th><span className="tc2" >Performance</span></th>
										        </tr>
										    </thead>
										    <tbody>
										    {
												Object.keys(markSheet.marks).map((sub,s_i)=>(
													<tr key={`sub_${s_i}`}>
														<td><span className="tc2" >{s_i+1}</span></td>
														<td><span className="tc2" >{sub}</span></td>
														<td><span className="tc2" >{markSheet.marks[sub].value}</span></td>
														<td><span className="tc2" >{markSheet.marks[sub].s_ten_score}</span></td>
														<td><span className="tc2" >{markSheet.marks[sub].performance}</span></td>
													</tr>
												))
											}
											</tbody>
										</Table>
									):(<Alert variant="info mt-3" >Mark Sheet NOT found! </Alert>)
								):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
							}
			        		</div>
			        		<canvas  id="myChart" width="300" height="100" ></canvas>
		        		</div>
		        	</Modal.Body>
		        	<Modal.Footer>
		        		<ReactToPrint
					        trigger={() => <Button variant="primary" className="br3" ><i className="bi bi-printer"></i> Print</Button>}
					        content={() => printable.current}
					      />
		        	</Modal.Footer>
        		</Modal>				
			</Container>
		)
}