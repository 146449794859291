import React, { useContext, useState, useEffect } from 'react';
import { Container, Alert, Spinner, Button, Table, Pagination, Form, Modal, Row, Col } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const ManualEntry=()=>{
	const { postCall, getManualMarkListLoad, manualMarkList, setManualMarkList } = useContext(AppContext);

	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState();
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=25;	
	// number of pages in pagination
	const pageCount = 10;
	useEffect(()=>{
		if (manualMarkList!==undefined) {
			let numStud = Object.keys(manualMarkList).length;
			setNumPage(Math.ceil(numStud/itemCount));
			setActivePage(0);
		}
	},[manualMarkList]);
	useEffect(()=>{
		setStart(0);
		setIndexStart(0);
	},[numPage]);

	const [ studMarks, setStudMarks ] = useState();
	const [ getStudMarksLoad, setGetStudMarksLoad ] = useState(false);
	const [ activeStud, setActiveStud ] = useState();
	const [ manualEntryModal, setManualEntryModal ] = useState(false);
	const addMarks=(sid)=>{
		if (sid!=="") {
			setActiveStud(sid);
			setGetStudMarksLoad(true);
			postCall('admin/get/manual/mark/sheet/',{s_id:sid.substring(1)}).then(res=>{
				setGetStudMarksLoad(false);
				setStudMarks(res.data);
				setManualEntryModal(true);
			}).catch(err=>{
				setGetStudMarksLoad(false);
				setActiveStud();

			});
		}
	}
	const [ approveAllLoad, setApproveAllLoad ] = useState(false);
	const [ postMarkLoad, setPostMarkLoad ] = useState(false);
	const [ subId, setSubId ] = useState();
	const postMark=(e)=>{		
		e.preventDefault();
		let tid = e.target.testpaper.value;
		let mark = e.target.mark.value;
		if (mark!==""&&tid!=="") {
			setPostMarkLoad(true);
			setSubId(tid);
			postCall('admin/enter/manual/mark/',{s_id:activeStud.substring(1),qs_id:tid.substring(1),mark:mark}).then(res=>{
				setPostMarkLoad(false);
				setStudMarks(res.data);
				setSubId();
			}).catch(err=>{
				setPostMarkLoad(false);
				setSubId();

			});
		}
	}
	return(
			<Container className="py-3"  >
				{
					getManualMarkListLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					):(
						manualMarkList!==undefined?(
						Object.keys(manualMarkList).length>0?(
							<div className="d-grid px-2 py-1 bg-greay-1 table-head-border al-center new-student-header">
								<span className="d-grid tc1 f20 f100 " >Students</span>
							</div>
							):''
						):''
					)
				}
				{
					manualMarkList!==undefined?(
						Object.keys(manualMarkList).length>0?(
							<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th><span className="tc2" >Name</span></th>
							          <th><span className="tc2" >Class</span></th>
							          <th><span className="tc2" >School</span></th>
							          <th><span className="tc2" >Contact Number</span></th>
							          <th><span className="tc2" >Email</span></th>
							          <th>
							          	
							          </th>
							        </tr>
							    </thead>
							    <tbody>
							    {
									Object.keys(manualMarkList).map((student,s_i)=>(
										activePage!==undefined&&s_i>=activePage*itemCount&&s_i<activePage*itemCount+itemCount?(
											<tr key={`satud_${s_i}`}>
												<td><span className="tc2" >{manualMarkList[student].name}</span></td>
												<td><span className="tc2" >{manualMarkList[student].class}</span></td>
												<td><span className="tc2" >{manualMarkList[student].institute}</span></td>
												<td><span className="tc2" >{manualMarkList[student].phone_numbers}</span></td>
												<td><span className="tc2" >{manualMarkList[student].email}</span></td>
												<td>
												{
													activeStud!==undefined&&activeStud===student&&getStudMarksLoad?(
														<Button size="sm" className="br3 btn-enable"><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
													):(
														<Button onClick={()=>{
															addMarks(student);
														}} size="sm" className="br3 btn-enable" ><i className="bi bi-check-circle"></i> Add marks</Button>
													)
												}
												</td>
											</tr>
										):''
									))
								}
								</tbody>
							</Table>
						):(<Alert variant="info mt-3" >List NOT found! </Alert>)
					):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
				}
				<div className="d-grid just-center py-2">
					{
					    numPage!==undefined&&numPage>1?(
							<Pagination>
						      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
						      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
						      	{
						      		Array(numPage).fill('1').map((item,i)=>(
						      			i>=indexStart&&i<indexStart+pageCount?(
				      						activePage!==undefined&&activePage===i?(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
				      						):(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
				      						)
				      					):''
				      				))
						      	}				      	
						      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
						      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
						    </Pagination>
						):''
					}
			    </div>
			    <Modal size="xl" show={manualEntryModal} backdrop="static" onHide={()=>{setManualEntryModal(false)}}>
		        	<Modal.Header closeButton>Manual mark updation</Modal.Header>
		        	<Modal.Body>
		        		{
		        			studMarks!==undefined?(
		        				<>	<div className="d-grid mb-3 bdr-1 bg-greay-1 py-2 br3">	
										<span className="f22 tex-center f200 tc1 px-2">{studMarks.exam_name}</span>
									</div>						
					        		<Row className="mb-3" >
					        			<Col xs={12} sm={6} md={6} >
					        				<div className="d-flex" ><span className="attr-key" >Name</span>:<span className="px-2" >{studMarks.name}</span></div>
					        				<div className="d-flex" ><span className="attr-key" >Phone</span>:<span className="px-2" >{studMarks.phone_numbers}</span></div>
					        			</Col>
					        			<Col xs={12} sm={6} md={6} >
					        				<div className="d-flex" ><span className="attr-key" >School</span>:<span className="px-2" >{studMarks.institute}</span></div>
					        				<div className="d-flex" ><span className="attr-key" >Class</span>:<span className="px-2" >{studMarks.class}</span></div>
					        			</Col>
					        		</Row>
					        	</>
		        			):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
		        		}
		        		<div className="d-grid">
			        		{
								studMarks!==undefined?(
									Object.keys(studMarks.marks).length>0?(
										<Table bordered className="tbl-bg-blue mb-3" >
										    <thead>
										        <tr>
										          <th><span className="tc2" >SI No</span></th>
										          <th><span className="tc2" >Sub.Test</span></th>
										          <th></th>
										        </tr>
										    </thead>
										    <tbody>
										    {
												Object.keys(studMarks.marks).map((sub,s_i)=>(
													<tr key={`sub_${s_i}`}>
														<td><span className="tc2" >{s_i+1}</span></td>
														<td><span className="tc2" >{studMarks.marks[sub].name}</span></td>
														<td>
															{
																studMarks.marks[sub].completed?(
																	<span className="tc2" >{studMarks.marks[sub].mark}</span>
																):(
																	<Form onSubmit={postMark}>
																		<input type="hidden" name="testpaper" value={sub} />
																		<div className="approve-form" >
																			<Form.Control type='number' name="mark" placeholder="mark" min="0" />
																			{
																				subId!==undefined&&subId===sub&&postMarkLoad?(
																					<Button size="sm" className="br3 btn-enable"><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																				):(
																					<Button type="submit" size="sm" className="br3 btn-enable" ><i className="bi bi-check-circle"></i> Approve</Button>
																				)
																			}
																		</div>
																	</Form>
																)
															}
														</td>
													</tr>
												))
											}
											</tbody>
										</Table>
									):(<Alert variant="info mt-3" >Mark Sheet NOT found! </Alert>)
								):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
							}
			        		</div>
		        	</Modal.Body>
        		</Modal>
			</Container>
		)
}