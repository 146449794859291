import React, { useContext, useState, useEffect } from 'react';
import { Container, Alert, Spinner, Button, Table, Pagination, Form } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const AdminHome=()=>{
	const { postCall, newStudents, setNewStudents, getNewStudentsLoad } = useContext(AppContext);

	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState();
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=25;	
	// number of pages in pagination
	const pageCount = 10;
	useEffect(()=>{
		if (newStudents!==undefined) {
			let numStud = Object.keys(newStudents).length;
			setNumPage(Math.ceil(numStud/itemCount));
			setActivePage(0);
		}
	},[newStudents]);
	useEffect(()=>{
		setStart(0);
		setIndexStart(0);
	},[numPage]);

	const [ activeStudent, setActiveStudent ] = useState();
	const [ approveLoad, setApproveLoad ] = useState(false);
	const approve=(e)=>{
		e.preventDefault();
		let student=e.target.student.value;
		let period=e.target.period.value;
		if (period==="") {
			$.confirm({
			    title: 'Please Choose a period',
			    type: 'red',
			    buttons: {   
			        ok: {
			        },
			    }
			});
		}
		if (student!==""&&period!=="") {
			setActiveStudent(student);
			setApproveLoad(true)
			postCall('admin/approve/student/',{s_id:student.substring(1),status:true, period: period}).then(res=>{
				setNewStudents(res.data);
				setActiveStudent();
				setApproveLoad(false)
			}).catch(err=>{
				setActiveStudent();
				setApproveLoad(false)
			});
		}
	}
	const [ approveAllLoad, setApproveAllLoad ] = useState(false);
	const approveAll=(e)=>{
		e.preventDefault();
		let period=e.target.period.value;
		if (period==="") {
			$.confirm({
			    title: 'Please Choose a period',
			    type: 'red',
			    buttons: {   
			        ok: {
			        },
			    }
			});
		}
		if (period!=="") {
			setApproveAllLoad(true)
			postCall('admin/approve/all/',{period: period}).then(res=>{
				setNewStudents(res.data);
				setApproveAllLoad(false)
			}).catch(err=>{
				setActiveStudent();
				setApproveAllLoad(false)
			});
		}
	}
	return(
			<Container className="py-3"  >
				{
					getNewStudentsLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					):(
						newStudents!==undefined?(
						Object.keys(newStudents).length>0?(
							<div className="d-grid px-2 py-1 bg-greay-1 table-head-border al-center new-student-header">
								<span className="d-grid tc1 f20 f100 " >New students registrations</span>
								{
					          		approveAllLoad?(
										<Button size="sm" className="br3 btn-enable"><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
									):(
										<Form onSubmit={approveAll}>
											<div className="approve-form-header" >
												<Form.Select name="period" >
													<option value="" >Choose period</option>
													{
														Array(21).fill('1').map((item,i)=>(
															<option value={10+i} >{10+i}</option>
														))
													}
												</Form.Select>
												<Button type="submit" size="sm" className="br3 btn-enable" ><i className="bi bi-check-circle"></i> Approve All</Button>
												</div>
										</Form>
									)
					          	}
							</div>
							):''
						):''
					)
				}
				{
					newStudents!==undefined?(
						Object.keys(newStudents).length>0?(
							<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th><span className="tc2" >Name</span></th>
							          <th><span className="tc2" >Class</span></th>
							          <th><span className="tc2" >School</span></th>
							          <th><span className="tc2" >Contact Number</span></th>
							          <th><span className="tc2" >Email</span></th>
							          <th>
							          	
							          </th>
							        </tr>
							    </thead>
							    <tbody>
							    {
									Object.keys(newStudents).map((student,s_i)=>(
										activePage!==undefined&&s_i>=activePage*itemCount&&s_i<activePage*itemCount+itemCount?(
											<tr key={`satud_${s_i}`}>
												<td><span className="tc2" >{newStudents[student].name}</span></td>
												<td><span className="tc2" >{newStudents[student].class}</span></td>
												<td><span className="tc2" >{newStudents[student].institute}</span></td>
												<td><span className="tc2" >{newStudents[student].phone_numbers}</span></td>
												<td><span className="tc2" >{newStudents[student].email}</span></td>
												<td>
													{
														newStudents[student].approve?(
															<span className="sucess-tag f16 px-3 py-1 mx-2 br3" ><i className="bi bi-check-circle"></i> Approved</span>
														):(
															activeStudent===student&&approveLoad?(
															<Button size="sm" className="br3 btn-enable"><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
															):(
															<Form onSubmit={approve}>
																<input type="hidden" name="student" value={student} />
																<div className="approve-form" >
																<Form.Select name="period br3" >
																	<option value="" >Choose period</option>
																	{
																		Array(21).fill('1').map((item,i)=>(
																			<option value={10+i} >{10+i}</option>
																		))
																	}
																</Form.Select>
																<Button type="submit" size="sm" className="br3 btn-enable" ><i className="bi bi-check-circle"></i> Approve</Button>
																</div>
															</Form>
															)
														)
													}
												</td>
											</tr>
										):''
									))
								}
								</tbody>
							</Table>
						):(<Alert variant="info mt-3" >New Registrations NOT found! </Alert>)
					):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
				}
				<div className="d-grid just-center py-2">
					{
					    numPage!==undefined&&numPage>1?(
							<Pagination>
						      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
						      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
						      	{
						      		Array(numPage).fill('1').map((item,i)=>(
						      			i>=indexStart&&i<indexStart+pageCount?(
				      						activePage!==undefined&&activePage===i?(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
				      						):(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
				      						)
				      					):''
				      				))
						      	}				      	
						      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
						      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
						    </Pagination>
						):''
					}
			    </div>
			</Container>
		)
}