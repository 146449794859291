import React, {useState, useContext, useEffect } from 'react';
import { Container, Alert, Spinner, Button, Table, Pagination } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const AllStudents=()=>{
	const { postCall, allStudents, setAllStudents, getAllStudentsLoad } = useContext(AppContext);

	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState();
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=25;	
	// number of pages in pagination
	const pageCount = 10;
	useEffect(()=>{
		if (allStudents!==undefined) {
			let numStud = Object.keys(allStudents).length;
			setNumPage(Math.ceil(numStud/itemCount));
			setActivePage(0);
		}
	},[allStudents]);
	useEffect(()=>{
		setStart(0);
		setIndexStart(0);
	},[numPage]);

	const [ activeStud, setActiveStud ] = useState()
	const [ passResetLoad, setPassResetLoad ] = useState(false);
	return(
			<Container className="py-3"  >
				{
					getAllStudentsLoad?(
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					):(
						allStudents!==undefined?(
						Object.keys(allStudents).length>0?(
							<span className="d-grid tc1 f20 f100 px-2 py-1 bg-greay-1 table-head-border" >All students</span>
							):''
						):''
					)
				}
				{
					allStudents!==undefined?(
						Object.keys(allStudents).length>0?(
							<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th><span className="tc2" >Name</span></th>
							          <th><span className="tc2" >Class</span></th>
							          <th><span className="tc2" >School</span></th>
							          <th><span className="tc2" >Contact Number</span></th>
							          <th><span className="tc2" >Email</span></th>
							          <th><span className="tc2" >Status</span></th>
							        </tr>
							    </thead>
							    <tbody>
							    {
									Object.keys(allStudents).map((student,s_i)=>(
										activePage!==undefined&&s_i>=activePage*itemCount&&s_i<activePage*itemCount+itemCount?(
											<tr key={`satud_${s_i}`}>
												<td><span className="tc2" >{allStudents[student].name}</span></td>
												<td><span className="tc2" >{allStudents[student].class}</span></td>
												<td><span className="tc2" >{allStudents[student].institute}</span></td>
												<td><span className="tc2" >{allStudents[student].phone_numbers}</span></td>
												<td><span className="tc2" >{allStudents[student].email}</span></td>
												<td>
													<div className="d-flex gap-1 just-center al-center">
													{
														allStudents[student].approve?(
															<span className="sucess-tag f14 px-3 py-1 mx-2 br3" ><i className="bi bi-check-circle"></i> Approved</span>
														):(
															<span className="error-message f14 px-3 py-1 mx-2 br3" ><i className="bi bi-x-circle"></i> NOT Approved</span>
														)
													}
													{
														activeStud!==undefined&&activeStud===student&&passResetLoad?(
															<Button variant="primary" className="br3" size="sm" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
														):(
															<Button onClick={()=>{
																setActiveStud(student);
																setPassResetLoad(true);
																postCall('admin/reset/student/password/',{sid:student.substring(1)}).then(res=>{
																	setActiveStud();
																	setPassResetLoad(false);
																	$.dialog({
		              													title: "Message",
		              													type: 'blue',
		              													content:res.data,
		              												});
																}).catch(err=>{
																	setActiveStud();
																	setPassResetLoad(false);
																	$.dialog({
		              													title: "Error Message",
		              													type: 'red',
		              													content:'Something went wrong!. Please try again or contact admin.',
		              												});
																});
															}} variant="primary" className="br3" size="sm"><i className="bi bi-key"></i> Reset Password</Button>
														)
													}
													
													</div>
												</td>
											</tr>

										):''
									))
								}
								</tbody>
							</Table>
						):(<Alert variant="info mt-3" >Registrations NOT found!</Alert>)
					):(<Alert variant="warning mt-3" className="t-red br-3" >Something went wrong!</Alert>)
				}
				<div className="d-grid just-center py-2">
					{
					    numPage!==undefined&&numPage>1?(
							<Pagination>
						      <Pagination.First onClick={()=>{setIndexStart(pre=>(0))}} />
						      <Pagination.Prev onClick={()=>{if(indexStart>0){setIndexStart(pre=>(pre-pageCount));}}} />				      	
						      	{
						      		Array(numPage).fill('1').map((item,i)=>(
						      			i>=indexStart&&i<indexStart+pageCount?(
				      						activePage!==undefined&&activePage===i?(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} active>{i+1}</Pagination.Item>
				      						):(
				      							<Pagination.Item onClick={()=>{setActivePage(i)}} key={`item_${i}`} >{i+1}</Pagination.Item>
				      						)
				      					):''
				      				))
						      	}				      	
						      <Pagination.Next onClick={()=>{if (indexStart<numPage) {setIndexStart(pre=>(pre+pageCount));}}} />
						      <Pagination.Last onClick={()=>{setIndexStart(pre=>(numPage-1))}} />
						    </Pagination>
						):''
					}
			    </div>
			</Container>
		)
}