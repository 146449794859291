import React, { useContext } from 'react';
import { Home } from './home';
import { AdminHome } from './adminHome';
import { AllStudents } from './allStudents';
import { MarkList } from './markList';
import { ManualEntry } from './manualMarkList';
import { AppContext } from '../App';


export const Dashboard=()=>{
	const { page } = useContext(AppContext);
	return(
			<div className="container-main">
				{
					page !==undefined?(
						page === 'home'?(
							<Home />							
						):page==='admin_home'?(
							<AdminHome />
						):page==='all_students'?(
							<AllStudents />
						):page==='mark_list'?(
							<MarkList />
						):page==='mark_list_manual'?(
							<ManualEntry />
						):''
					):''
				}
        	</div>
		)
}