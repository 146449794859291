import React, {useState, useContext} from 'react';
import {Container, Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const Authentication=()=>{
	const { setToken, link, setUser, postCall, setPage } = useContext(AppContext);
	const [ loginError, setLoginError ] = useState(false);
	const [ load, setLoad ] = useState(false);
	const AuthCheck=(token)=>{
		axios.get(`${link}api/auth/check/`,{
			headers:{
              Authorization: 'Bearer '+token
            }
		}).then(response=>{
			setLoad(false);
			setUser(response.data);
			setToken(token);
			setPage('home');
		}).catch(err=>{
			setLoad(false);
			setLoginError(true);
	   		setTimeout(()=>{setLoginError(false)},5000);
	   		setToken();
	   		if (err!==undefined) {
				if(err.response.status === 401){
			        console.log('Auth_check - ',err.response.status,':',err.response.statusText);
			    }else if(err.response.status === 404){
			        console.log('Auth_check - ',err.response.status,':',err.response.statusText);
			    }else if(err.response.status === 500){
			        console.log('Auth_check - ',err.response.status,':',err.response.statusText);
			    }else{
			        console.log('Auth_check - ',err.response.status,':',err.response.data);
			    }
			}else{
		        $.dialog({
		          title: "Can't reach server!",
		          content: 'Please check internet connection!',
		        });
		    }
		});
  	}
	const requestUser=(event)=>{		
		event.preventDefault();
		if (event.target.username.value!==""&&event.target.password.value!=="") {
			setLoad(true);
			postCall('token/',{
		   		username : event.target.username.value,
		   		password : event.target.password.value,
		   	}).then(res=>{
		   		AuthCheck(res.data.access);
			}).catch(err=>{
				setLoad(false);
		   		setLoginError(true);
		   		setTimeout(()=>{setLoginError(false)},5000);
			});
		}
	}
	const [ regError, setRegError ] = useState(false);
	const [ nameError, setNameError ] = useState(false);
	const [ schoolErr, setSchoolErr ] = useState(false);
	const [ numberErr, setNumberErr ] = useState(false);
	const [ classErr, setClassErr ] = useState(false);
	const [ emailError, setEmailError ] = useState(false);
	const [ userError, setUserError ] = useState(false);
	const [ passError, setPassError ] = useState(false);
	const [ cpassError, setCpassError ] = useState(false);
	const [ passMatchError, setPassMatchError ] = useState(false);
	const [ regLoad, setRegLoad ] = useState(false);
	const registerUser=(e)=>{
		e.preventDefault();
		let fname=e.target.first_name.value;
		let lname=e.target.last_name.value;
		let school=e.target.school.value;
		let number=e.target.number.value;
		let className=e.target.class_name.value;
		let email=e.target.email.value;
		let username=e.target.username.value;
		let pass=e.target.password.value;
		let cpass=e.target.password2.value;
		if(fname===""){
			setNameError(true);
			setTimeout(()=>{setNameError(false)},5000);
		}
		if(school===""){
			setSchoolErr(true);
			setTimeout(()=>{setSchoolErr(false)},5000);
		}
		if(number===""){
			setNumberErr(true);
			setTimeout(()=>{setNumberErr(false)},5000);
		}
		if(className===""){
			setClassErr(true);
			setTimeout(()=>{setClassErr(false)},5000);
		}
		if(email===""){
			setEmailError(true);
			setTimeout(()=>{setEmailError(false)},5000);
		}
		if(username===""){
			setUserError(true);
			setTimeout(()=>{setUserError(false)},5000);
		}
		if(pass===""){
			setPassError(true);
			setTimeout(()=>{setPassError(false)},5000);
		}
		if(cpass===""){
			setCpassError(true);
			setTimeout(()=>{setCpassError(false)},5000);
		}
		if(pass!==""&&cpass!==""&&pass!==cpass){
			setPassMatchError(true);
			setTimeout(()=>{setPassMatchError(false)},5000);
		}
		if (fname!==""&&username!==""&&email!==""&&pass!==""&&cpass!==""&&pass===cpass&&validUsername!==undefined&&!validUsername
			&&validEmail!==undefined&&!validEmail&&className!==""&&school!=="") {
			setRegLoad(true);
			postCall('register/',{
				first_name:fname,
				last_name:lname,
				username:username,
				password:pass,
				mob_number:number,
				email:email,
				school:school,
				class_name:className,
			}).then(res=>{
				setRegLoad(false);
				if (res.data) {
					document.querySelector('#register-form').classList.add('hide');
					document.querySelector('#login-form').classList.remove('hide');
					$.dialog({
		              title: "Registration successful!",
		              type: 'green',
		              content: 'Please login with username and password.',
		            });
				}else{
					$.dialog({
		              title: "Account already exist!",
		              type: 'orange',
		              content: 'Please try another username or email!',
		            });
				}
			}).catch(err=>{
				setRegLoad(false);
				setRegError(true);
		   		setTimeout(()=>{setRegError(false)},5000);
			});
		}
	}
	const [ checkUserLoad, setCheckUserLoad ] = useState(false);
	const [ validUsername, setValidUsername ] = useState();	
	const checkUsername=(username)=>{
		if (username!=="") {
			setCheckUserLoad(true);
			setUserError(false);
			postCall('get/user/name/',{username:username}).then(res=>{
				setCheckUserLoad(false);
				setValidUsername(res.data);
			}).catch(err=>{
				setCheckUserLoad(false);
			});
		}else{
			setUserError(true);
			setValidUsername();
		}
	}
	const [ validEmail, setValidEmail ] = useState();
	const [ checkEmailLoad, setCheckEmailLoad ] = useState(false);
	const checkEmail=(email)=>{
		if (email!=="") {
			setCheckEmailLoad(true);
			setEmailError(false);
			postCall('get/email/id/',{email:email}).then(res=>{
				setCheckEmailLoad(false);
				setValidEmail(res.data);
			}).catch(err=>{
				setCheckEmailLoad(false);
			});

		}else{
			setEmailError(true);
			setValidEmail();
		}
	}	
	
	
	return (
		<Container className="login-wrapper" >
			<Row className="login-window" >
						<Col className="login-right p-5" id="login-form">
							<h4 className="tc2 py-3 text-center">Login</h4>
							{
								loginError!==undefined&&loginError?(
									<Alert variant="warning" className="t-red br-3" >Invalid credentials!</Alert>
								):''
							}
							<Form onSubmit={requestUser} className="login-form" >
						      <Form.Group className="mb-3 text-white">
						        <Form.Control type="text" name="username" required className="shadow-none" placeholder="Username" />
						      </Form.Group>
						      <Form.Group className="mb-3  text-white">
						        <Form.Control type="password" name="password" required className="shadow-none"  placeholder="Password" />
						      </Form.Group>
						      <div className="d-grid gap-2">
						      {
						      	load?(
							      	<Button variant="outline-primary" disabled>
							      	 <span><Spinner animation="border" size="sm" /> Please wait...</span></Button>
							      ):(
							      	<Button type="submit" className="login-btn" >LOGIN</Button>
							      )
							  }
							  </div>
					    	</Form>
					    	<p className="py-3 tc2 text-center" >Not registered? <span onClick={()=>{
					    		document.querySelector('#register-form').classList.remove('hide');
					    		document.querySelector('#login-form').classList.add('hide');
					    	}} className="text-link">click here</span> to register.</p>
						</Col>
						<Col className="login-right p-5 hide" id="register-form">
							<h4 className="tc2 py-3 text-center">Register</h4>
							{
								regError!==undefined&&loginError?(
									<Alert variant="warning" className="t-red br-3" >Something went wrong!</Alert>
								):''
							}
							<Form onSubmit={registerUser} className="login-form" >
							  <Form.Group className="mb-3 text-white">
							  	{nameError?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						        <Form.Control type="text" name="first_name" className="shadow-none" placeholder="First name*" />
						      </Form.Group>
						      <Form.Group className="mb-3 text-white">
						        <Form.Control type="text" name="last_name" className="shadow-none" placeholder="Last name" />
						      </Form.Group>
						      <Form.Group className="mb-3 text-white">
						      	{numberErr?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						        <Form.Control type="tel" pattern="[0-9]{10}" name="number" className="shadow-none" placeholder="Contact number" required />
						      </Form.Group>						      
						      <Form.Group className="mb-3 text-white">
						      	{schoolErr?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						        <Form.Control type="text" name="school" className="shadow-none" placeholder="School name*" />
						      </Form.Group>
						      	{classErr?(<Form.Label><span className="error-message" >You have to choose your class.</span></Form.Label>):''}
						      <Form.Select name="class_name" className="mb-3" >
							      <option value="" >Choose your class*</option>
							      <option value="IX">IX</option>
							      <option value="X">X and Above</option>
							  </Form.Select>
						      <Form.Group className="mb-3 text-white">
						      	{emailError?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
				    			{
	        						checkEmailLoad?(
	        							<Form.Label>
	        								<span className="please-wait-spinner c1" ><Spinner animation="border" size="sm" /> Checking availability...</span>
	        							</Form.Label>
	        						):(
			        					validEmail!==undefined?(
			        						validEmail?(
			        						<Form.Label>
			        							<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
			        						</Form.Label>
			        						):(
			        						<Form.Label>
			        							<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
			        						</Form.Label>
			        						)
			        					):''
		        					)
		        				}
						        <Form.Control onBlur={(e)=>{checkEmail(e.target.value)}} onKeyDown={(e)=>{
						        	if (e.key === " ") {e.preventDefault();}
						        }} type="email" name="email" required className="shadow-none" placeholder="Email id*" />
						      </Form.Group>
						      <Form.Group className="mb-3 text-white">
						      	{userError?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						      	{
	        						checkUserLoad?(
	        							<Form.Label>
	        								<span className="please-wait-spinner c1" ><Spinner animation="border" size="sm" /> Checking availability...</span>
	        							</Form.Label>
	        						):(
			        					validUsername!==undefined?(
			        						validUsername?(
			        						<Form.Label>
			        							<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
			        						</Form.Label>
			        						):(
			        						<Form.Label>
			        							<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
			        						</Form.Label>
			        						)
			        					):''
		        					)
		        				}
						        <Form.Control onBlur={(e)=>{checkUsername(e.target.value)}} onKeyDown={(e)=>{
						        	if (e.key === " ") {e.preventDefault();}
						        }} type="text" name="username" required className="shadow-none" placeholder="Username*" />
						      </Form.Group>
						      <Form.Group className="mb-3  text-white">
						      	{passError?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						      	{passMatchError?(<Form.Label><span className="error-message" >Passwords do NOT match!</span></Form.Label>):''}
						        <Form.Control type="password" name="password" required className="shadow-none"  placeholder="Password*" />
						      </Form.Group>
						      <Form.Group className="mb-3  text-white">
						        {cpassError?(<Form.Label><span className="error-message" >This field is required</span></Form.Label>):''}
						        <Form.Control type="password" name="password2" required className="shadow-none"  placeholder="Confirm Password*" />
						      </Form.Group>
						      <div className="d-grid gap-2">
						      {
						      	regLoad?(
							      	<Button variant="outline-primary" disabled>
							      	 <span><Spinner animation="border" size="sm" /> Please wait...</span></Button>
							      ):(
							      	<Button type="submit" className="login-btn" >Register</Button>
							      )
							  }
							  </div>
					    	</Form>
					    	<p className="py-3 tc2 text-center" >Already have an account? <span onClick={()=>{
					    		document.querySelector('#register-form').classList.add('hide');
					    		document.querySelector('#login-form').classList.remove('hide');
					    	}} className="text-link">click here</span> to Login.</p>
						</Col>
			</Row>
		</Container>
		);
}