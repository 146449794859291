import React, {useContext} from 'react';
import { AppContext } from '../App';
import { Container, Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap';

export const Menu=()=>{
	const { user, setPage, getCall, setNewStudents, setGetNewStudentsLoad, setAllStudents, setGetAllStudentsLoad,
	setMarkList, setGetMarkListLoad, setGetManualMarkListLoad, setManualMarkList } = useContext(AppContext);
	return(
		<div className="bg-greay-1 bdr-1b">
		<Container className="d-grid">
			<Navbar expand="lg" className="bg-body-tertiary">
				{
					user!==undefined&&user.type!==undefined&&user.type==='student'?(
						<Navbar.Brand className="tc2" >Online Examination</Navbar.Brand>
					):''
				}		        
		        <Navbar.Toggle aria-controls="basic-navbar-nav" />
		        <Navbar.Collapse id="basic-navbar-nav">		          
		          {
		          	user!==undefined&&user.type!==undefined&&user.type==='admin'?(
		          		<Nav className="me-auto">
			          		<Nav.Link onClick={()=>{setPage('admin_home')}} >Home</Nav.Link>
				            <NavDropdown title="Students">
				              <NavDropdown.Item onClick={()=>{
				              	getCall(setGetNewStudentsLoad,'admin/new/students/',setNewStudents);
				              	setPage('admin_home');
				              }} >New Registration</NavDropdown.Item>
				              <NavDropdown.Divider />
				              <NavDropdown.Item onClick={()=>{
				              	getCall(setGetAllStudentsLoad,'admin/all/students/',setAllStudents);
				              	setPage('all_students');
				              }} >All students</NavDropdown.Item>
				            </NavDropdown>
				            <NavDropdown title="Examination" >
				              <NavDropdown.Item onClick={()=>{
				              	getCall(setGetMarkListLoad,'admin/get/mark/list/',setMarkList);
				              	setPage('mark_list');
				              }} >Mark list</NavDropdown.Item>
				              <NavDropdown.Item onClick={()=>{
				              	getCall(setGetManualMarkListLoad,'admin/get/manual/mark/list/',setManualMarkList);
				              	setPage('mark_list_manual');
				              }} >Manual mark entry</NavDropdown.Item>
				            </NavDropdown>
			            </Nav>
		          	):(
		          		<Nav className="me-auto">
		          		</Nav>
		          	)
		          }
		          
		          <Dropdown align='end' >
		            <Dropdown.Toggle variant="outline-secondary" id="dropdown-menu-align-end" className="menu1" >
		              {
		              	user!==undefined&&user.account!==undefined?user.account.name:'Username'
		              }
		            </Dropdown.Toggle>
		            <Dropdown.Menu className="menu-one" >
		              <Dropdown.Item eventKey="4" onClick={()=>{
		                localStorage.clear();
		                window.location.reload();
		                }} >
		                <span className="menu-icon" ><i className="bi bi-box-arrow-left"></i></span>
		                <span className="menu-name" >Log out</span>
		              </Dropdown.Item>
		            </Dropdown.Menu>
		        </Dropdown>
		        </Navbar.Collapse>
		    </Navbar>
		</Container>
		</div>
		)
}